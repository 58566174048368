import { ref } from "vue"

import { useRouter, useRoute } from "vue-router"

import i18n from "@/i18n"
import mixins from "@/mixins/modal.js"

import { updateCylinderAccess } from "@/services/intratone/access.js"
import { getAPIKiboltURL } from "@/services/httpClient.js"

const {
    global: { t },
} = i18n

let Prog = window.Prog
let progKiboltConnected = ref(false)
let cylinderConnected = ref(false)
let cylinderStatus = ref("")
let connectingToProgram = ref(false)
let notInstalled = ref(true)
let cylinderType = ref(2)
let cylinderApi = null
let typeActionCylinder = "update"
let errorMessage = ref("")
let emitter = null
let router = null
let route = null

export function useProg(setEmitter) {
    emitter = setEmitter
    router = useRouter()
    route = useRoute()

    function progProcess(secure, typeProg, actionCylinder, cylinder) {
        typeActionCylinder = actionCylinder.value
        cylinderApi = cylinder
        // Test si le system est compatible
        Prog.test.system()
        // Test si le navigateur est compatible
        Prog.test.nav()

        // Prog.install()

        Prog.init(afterInit, afterConnect, secure, typeProg)

        if (!Prog.compatible()) {
            console.log(t("TRY_AGAIN"))

            mixins.methods.openModal(
                "alert",
                {
                    title: "device_using_program_accessories_not_compatible_USB_programmers_use_computer",
                    refuse: () => {
                        router.push({
                            name: "CylinderDetails",
                            params: route.params,
                        })
                    },
                },
                emitter
            )
        }

        if (Prog.needMaj) {
            mixins.methods.openModal(
                "validation",
                {
                    title: "you_need_update_programmer",
                    validateButton: t("TRY_AGAIN"),
                    valid: () => {
                        window.location.reload()
                    },

                    refuse: () => {
                        router.push({
                            name: "CylinderDetails",
                            params: route.params,
                        })
                    },
                },
                emitter
            )
        }
    }

    return { progProcess: progProcess }
}

function afterInit(response) {
    if (response.error && response.error === 404) {
        Prog.close()
    }

    notInstalled.value = false
    if (response.connect !== 1) {
        return
    }
    progKiboltConnected.value = true
    connectingToProgram.value = true
    progReadCylinder({
        action: "kbtReadCylinder",
        callback: callbackReadCylinder,
    })
}

function progReadCylinder({ action, callback }) {
    const payload = payloadProgRequest(action)
    Prog.request(payload, callback)
}

async function callbackReadCylinder(response) {
    if (response.serial) {
        cylinderConnected.value = true

        cylinderType.value = parseInt(response.type)
        cylinderStatus.value = response.state

        if (
            !isCorrectCylinderInserted(response.serial) &&
            typeActionCylinder == "update"
        ) {
            errorMessage.value = t(
                "kibolt.cylinder_not_correspond_cylinder_updated_insert_correct_cylinder"
            )
            cylinderStatus.value = "error"
            return
        }
        if (typeActionCylinder == "replace") {
            const resReplace = await replaceCylinderApi({ wsRead: response })
            if (resReplace.error) {
                resReplace.code == "CYLINDER_SERIAL_VERIFY_FAILED"
                    ? (errorMessage.value = t(
                          "kibolt.cylinder_already_exists_insert_another_cylinder"
                      ))
                    : (errorMessage.value = t(
                          "kibolt.error_occurred_during_update"
                      ))
                cylinderStatus.value = "error"
                return
            }
        }

        progActionCylinder({
            action: "kbtWriteCylinderData",
            callback: callbackWriteCylinder,
            serial: response.serial,
        })
    }
}
function progActionCylinder({ action, callback, serial }) {
    const payload = payloadProgRequest(action, serial)
    Prog.request(payload, callback)
    cylinderStatus.value = "updating"
}
async function callbackWriteCylinder(response) {
    if (response.state === "OK") {
        cylinderStatus.value = "updateEnd"
    }
    if (response.error === 1) {
        cylinderStatus.value = "error"
    }
}
function afterConnect(e) {
    console.log(e)
    if (!e) {
        // L'application répond bien
        notInstalled.value = false
    } else {
        // L'application renvoie une erreur
        if (e.error && e.error === "too many client") {
            // Cas ou on est déjà connecté sur un autre site (ex: intratone.info)
            if (e.host && e.host !== "") {
                mixins.methods.openModal(
                    "validation",
                    {
                        title: "window_open_site_in_host_please_close_it",
                        name: '"' + e.host + '"',
                        validateButton: t("TRY_AGAIN"),
                        valid: () => {
                            window.location.reload()
                        },
                        refuse: () => {
                            router.push({
                                name: "CylinderDetails",
                                params: route.params,
                            })
                        },
                    },
                    emitter
                )
            }
        }
    }
}
function payloadProgRequest(nameFn, serial = null) {
    let payload = {
        function: nameFn,
        url: getAPIKiboltURL(),
    }
    if (serial) {
        payload.serial = serial
    }

    return payload
}
function isCorrectCylinderInserted(serialCylinderInserted) {
    return cylinderApi.serial == serialCylinderInserted
}

async function replaceCylinderApi({ wsRead }) {
    let typeName = "DOUBLE"
    let libelle = "Canon de porte de type double"
    switch (wsRead.type) {
        case 1:
            typeName = "SIMPLE"
            libelle = "Canon de porte de type simple"
            break
        case 2:
            typeName = "DOUBLE"
            libelle = "Canon de porte de type double"
            break
        case 3:
            typeName = "ABOUTON"
            libelle = "Canon de porte de type bouton"
            break
        default:
            typeName = "DOUBLE"
            libelle = "Canon de porte de type double"
    }

    const paramsUpdate = {
        type: wsRead.type,
        type_name: typeName,
        serial: wsRead.serial,
        libelle: libelle,
        dim_int: wsRead.dimint,
        dim_ext: wsRead.dimext,
    }
    return await updateCylinderAccess({
        id: cylinderApi.id,
        params: paramsUpdate,
    })
}

export function retryUpdate() {
    cylinderStatus.value = "updating"
    errorMessage.value = ""
    progReadCylinder({
        action: "kbtReadCylinder",
        callback: callbackReadCylinder,
    })
}

export function useProgStates() {
    return {
        progKiboltConnected,
        cylinderConnected,
        cylinderStatus,
        cylinderType,
        errorMessage,
        Prog,
    }
}

export function reset() {
    progKiboltConnected = ref(false)
    cylinderConnected = ref(false)
    cylinderStatus = ref("")
    connectingToProgram = ref(false)
    notInstalled = ref(true)
    cylinderType = ref(2)
    cylinderApi = null
    typeActionCylinder = "update"
    errorMessage = ref("")
}
