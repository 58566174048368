import { ref } from "vue"
import { goToV1ViewInit } from "@/composables/routing.js"

let modalState = ref(false)

export function useOpenModal() {
    modalState.value = true
}

export function useCloseModal(router = null, redirection = null) {
    modalState.value = false
    if (router) {
        router.push({ path: redirection })
    }
}

export function useModalState() {
    return { modalState }
}
