
export function goToV1ViewInit(router) {
    return (path, blank = false, data = '') => {
        if (blank == true) {
            let route = router.resolve({ path: "/v1/" + path })
            window.open(route.href, "_blank")
        } else {
            router.push({ path: "/v1/" + path })
        }
    }

}

export function goToResidencesInit(router) {
    return () => {
        if (window.history.state.back.startsWith("/residences?")) {
            history.back()
        } else {
            router.push({
                name: "Residences",
            })
        }
    }
}

export function goTo404(router) {
    router.push({
        path: "/error/404",
    })
}

//permission denied
export function goTo401(router) {
    router.push({
        path: "/error/401",
    })
}
