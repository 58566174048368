<script setup>
"use strict"
import {
    defineComponent,
    defineProps,
    ref,
    onMounted,
    inject,
    onUnmounted,
    computed,
} from "vue"

import { useRoute, useRouter } from "vue-router"

const router = useRouter()
import { useI18n } from "vue-i18n"
const { t } = useI18n()
const emitter = inject("emitter")

import BasicButton from "@/components/basic/BasicButton.vue"
import BasicTitle from "@/components/basic/BasicTitle.vue"
import ResidenceBreadcrumb from "@/components/entities/ResidenceBreadcrumb.vue"
import DetectKiprog from "@/components/kibolt/DetectKiprog.vue"
import DetectCylinder from "@/components/kibolt/DetectCylinder.vue"
import UpdateCylinder from "@/components/kibolt/UpdateCylinder.vue"
import Validation from "@/components/Modal/Validation.vue"
import ClassicBreadCrumb from "@/components/entities/ClassicBreadCrumb.vue"

import { goTo401 } from "@/composables/routing.js"
import {
    useGetImageCylinder,
    useGetCylinderAccess,
    useGetImageKiprogOpen,
    useGetLabelButton,
    useGetTypeActionCylinder,
    useGetTitle,
} from "@/composables/kibolt.js"

import {
    useProg,
    retryUpdate,
    useProgStates,
    reset,
} from "@/composables/kiboltProg.js"
import { useModalState } from "@/composables/modal.js"

import { getResidenceSummaryById } from "@/services/intratone/residence.js"
import { getStairwellSummaryById } from "@/services/intratone/stairwell.js"

const { modalState } = useModalState()
const {
    progKiboltConnected,
    cylinderConnected,
    cylinderStatus,
    cylinderType,
    errorMessage,
    Prog,
} = useProgStates()
const { params: routeParams, path } = useRoute()

const typeProg = "kibolt"
const secure = 0
const actionCylinder = ref(null)
const cylinderApi = ref(null)
let breadcrumb = ref({})
let redirection = null

const { progProcess } = useProg(emitter)

onMounted(async () => {
    cylinderApi.value = await useGetCylinderAccess(routeParams.idcylinderaccess)

    if (cylinderApi.value.error) {
        goTo401(router)
        throw new Error(cylinderApi.value.code)
    }

    const {
        data: { path: pathForBreadcrumb },
    } =
        routeParams.type == "residence"
            ? await getResidenceSummaryById(routeParams.id)
            : await getStairwellSummaryById(routeParams.id)

    breadcrumb.value = { ...pathForBreadcrumb }
    breadcrumb.value.cylinder = { name: cylinderApi.value.data.nom, id: "" }
    breadcrumb.value.action =
        useGetTypeActionCylinder(path).value == "update"
            ? { name: t("UPDATING"), last: true }
            : { name: t("REPLACEMENT"), last: true }

    redirection = `/${routeParams.type}/${routeParams.id}/cylinder/${routeParams.idcylinderaccess}`

    actionCylinder.value = await useGetTypeActionCylinder(path)

    progProcess(secure, typeProg, actionCylinder.value, cylinderApi.value.data)
})

function endAction(state) {
    switch (state) {
        case "updateEnd":
            router.push({ path: redirection })
            break
        case "error":
            retryUpdate()
            break
        default:
            break
    }
}
onUnmounted(() => {
    reset()
    Prog.close()
})
defineComponent({
    name: "CylinderUpdate",
})
</script>

<template>
    <div class="block update-kibolt-container">
        <ClassicBreadCrumb
            :title="useGetTitle(useGetTypeActionCylinder(path))"
        />
        <div class="top-block">
            <basic-title>{{
                useGetTitle(useGetTypeActionCylinder(path))
            }}</basic-title>
        </div>
        <div class="body-block">
            <div class="flex block-step justify-center">
                <div class="block-left">
                    <detect-kiprog
                        :progKiboltConnected="progKiboltConnected"
                        class="step"
                    >
                    </detect-kiprog>
                    <detect-cylinder
                        v-if="progKiboltConnected"
                        :cylinderConnected="cylinderConnected"
                        :actionCylinder="useGetTypeActionCylinder(path).value"
                        class="step"
                    >
                    </detect-cylinder>
                    <update-cylinder
                        v-if="progKiboltConnected && cylinderConnected"
                        :cylinderStatus="cylinderStatus"
                        :errorMessage="errorMessage"
                        :actionCylinder="useGetTypeActionCylinder(path).value"
                        class="step"
                    >
                    </update-cylinder>
                    <div class="flex flex-row">
                        <basic-button
                            v-if="
                                cylinderStatus == 'updateEnd' ||
                                cylinderStatus == 'error'
                            "
                            @click="endAction(cylinderStatus)"
                            class="override-button"
                        >
                            {{
                                useGetLabelButton({
                                    cylinderStatus: cylinderStatus,
                                })
                            }}
                        </basic-button>
                        <basic-button
                            v-if="cylinderStatus == 'error'"
                            @click="endAction('updateEnd')"
                            class="override-button cancel"
                        >
                            {{ t("CANCEL") }}
                        </basic-button>
                    </div>
                </div>
                <div class="block-right">
                    <div v-if="!progKiboltConnected" class="relative">
                        <img
                            class="kiprog"
                            :src="useGetImageKiprogOpen()"
                            alt="kiprog"
                        />
                    </div>
                    <div v-else class="relative block-img">
                        <img
                            class="kiprog animation-kiprog-translate-right"
                            :src="useGetImageKiprogOpen()"
                            alt="kiprog"
                        />
                        <img
                            class="relative cylinder"
                            :src="useGetImageCylinder({ type: cylinderType })"
                            alt="cylinder"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="body">
        <div v-if="modalState" class="modal-mask">
            <div class="modal-container">
                <div class="title">
                    <slot name="header"></slot>
                </div>
                <validation :question="errorMessage">
                    <template #leftButton> Réessayer </template>
                    <template #rightButton> Annuler </template>
                </validation>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/kibolt/dashboard.scss";

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.relative {
    position: relative;
}

.font-bold {
    font-weight: 700;
}
.font-extrabold {
    font-weight: 800;
}
.text-xs {
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
}
.text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
}
.text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
}
.text-2xl {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
}
.orangeneutral {
    color: $orange-neutral;
}
.text-center {
    text-align: center;
}
.title {
    font-family: $font_avenir_black;
    font-size: $veryBig;
    width: 25%;
    margin: 0 !important;
}
.block {
    // padding: 40px 44px 0px 44px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.block-wrapper {
    width: max-content;
}

@media all and (min-width: 1024px) {
    .top-block-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1em;
        .top-block {
            width: 100%;
            max-width: 1448px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
        }
    }
}

.update-kibolt-container {
    padding: 20px 80px;
    display: flex;
    flex-direction: column;
    gap: 1em;

    @media screen and (max-width: 1024px) {
        padding: 2em 20px;
    }

    .top-block {
        margin: 0;
    }

    .body-block {
        padding-top: 3em;
    }
}
</style>
