<script setup>
import { defineProps, computed } from "vue"
import { useI18n } from "vue-i18n"
const { t } = useI18n()

const props = defineProps({
    cylinderStatus: {
        type: String,
        required: true,
    },
    errorMessage: {
        type: String,
        default: "",
    },
    actionCylinder: {
        type: String,
        default: "update",
    },
})

let errorMessage = computed(() => {
    return (
        props.errorMessage ||
        t("kibolt.be_careful_cylinder_not_functional_try_again")
    )
})
function getTitle() {
    return props.actionCylinder == "replace" ? t("REPLACEMENT") : t("UPDATING")
}
</script>
<template>
    <div>
        <h2>3. {{ getTitle() }}</h2>
        <template v-if="props.actionCylinder == 'update'">
            <p
                class="text-base statut-key wait"
                v-if="cylinderStatus == 'updating'"
            >
                {{
                    $t(
                        "kibolt.update_progress_not_unplug_Kiprog_duration_update"
                    )
                }}
            </p>

            <p
                class="text-base statut-key connected"
                v-if="cylinderStatus == 'updateEnd'"
            >
                {{ $t("kibolt.updates_completed_remove_kiprog_cylinder") }}
            </p>
        </template>
        <template v-else>
            <p
                class="text-base statut-key wait"
                v-if="cylinderStatus == 'updating'"
            >
                {{ $t("kibolt.replacement_in_progress") }} <br />
                {{ $t("kibolt.unplug_kiprog_duration_update") }}
            </p>
            <p
                class="text-base statut-key connected"
                v-if="cylinderStatus == 'updateEnd'"
            >
                {{ $t("kibolt.replacement_completed") }} <br />
                {{ $t("kibolt.can_remove_kiprog_cylinder") }}
            </p>
        </template>

        <p class="text-base statut-key error" v-if="cylinderStatus == 'error'">
            {{ errorMessage }}
        </p>
    </div>
</template>

<style lang="scss">
@import "@/assets/scss/kibolt/step.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

h2 {
    font-family: $font_avenir_heavy;
    font-size: $semiBig;
}
</style>
