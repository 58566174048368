import i18n from '@/i18n'
import { ref } from 'vue'

const { global:{t} } = i18n

import { getCylinderAccess } from "@/services/intratone/access.js"

async function cylinderAccess(id){
    return await getCylinderAccess(id)
}

export async function useGetCylinderAccess(id){
    const data = await cylinderAccess(id)
    return data
}

export function useGetImageCylinder({type}){
    let cylinderImg = ""
    switch (parseInt(type)) {
        case 1:
            cylinderImg = require('@/assets/images/kibolt/cylinder-simple.png')
        break;
        case 2:
            cylinderImg = require('@/assets/images/kibolt/cylinder-double.png')
        break;
        case 3:
            cylinderImg = require('@/assets/images/kibolt/cylinder-button.png')
        break;
        default:
            cylinderImg = require('@/assets/images/kibolt/cylinder-double.png')
        break;
    }
    return cylinderImg
}

export function useGetImageKiprogOpen(){
    const imageBaseURL = process.env.VUE_APP_IMAGES_URL
    return `${imageBaseURL}Images/Programmateur/kiprog-open.png`
}

export function useGetLabelButton({cylinderStatus}){
    let labelButton = ""
    switch (cylinderStatus) {
        case "updateEnd":
            labelButton = t("END")
            break;
        case "error":
            labelButton = t("TRY_AGAIN")
            break;
        default:
            break;
    }
    return labelButton
}

export function useGetTypeActionCylinder(path){
    let actionCylinder = ref("")
    const arraySplit = path.split("/")
    actionCylinder.value = arraySplit[arraySplit.length-1]
    return actionCylinder
}

export function useGetTitle(actionCylinder){
    return actionCylinder.value == "update" ? t('kibolt.kibolt_cylinder_update') : t('kibolt.kibolt_cylinder_replace')
}

